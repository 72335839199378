import React, { useState, useMemo, ReactNode, useEffect } from 'react'
import { useAuth } from '../zustand/auth.store';

// create context
export const AppContext = React.createContext<valueItf | null>(null)


interface valueItf {
    selectedOrder: any | null | object;
    handleSelectedOrder: any;
    selectedUser: any | null | object;
    handleSelectedUser: any;
    selectedTransaction: any | null | object;
    handleSelectedTransaction: any;
    selectedEscrow: any | null | object;
    handleSelectedEscrow: any;
    selectedProduct: any | null | object;
    handleSelectedProduct: any;
    handleSetUnreadMessage: any;
    unreadMessage: any;
    handleRemoveUnreadMessage: any;

}
interface AppITF {
    children: ReactNode
}


export const AppProvider = ({ children }: AppITF) => {
    const [selectedOrder, setSelectedOrder] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedTransaction, setSelectedTransaction] = useState(null)
    const [selectedEscrow, setSelectedEscrow] = useState(null)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [unreadMessage, setUnreadMessage] = useState<any>([])



    const handleSelectedOrder = (val: any) => {
        setSelectedOrder(val)
    }

    const handleSelectedUser = (val: any) => {
        setSelectedUser(val)
    }

    const handleSelectedTransaction = (val: any) => {
        setSelectedTransaction(val)
    }
    const handleSetUnreadMessage = (val: any) => {
        const copyUnread = [...unreadMessage]
        const exists = copyUnread.some((msg: any) => msg.id === val.id);
        console.log(exists)
        if (exists === false) {
            setUnreadMessage((prevMessages: any[]) => [...prevMessages, val]);
        }
    };

    const handleRemoveUnreadMessage = (val: any) => {
        // console.log(val)
        const filteredMessages = unreadMessage.filter((message: any) => message.helpdeskId != val);
        setUnreadMessage(filteredMessages);
    }

    const handleSelectedEscrow = (val: any) => {
        setSelectedEscrow(val)
    }

    const handleSelectedProduct = (val: any) => {
        setSelectedProduct(val)
    }




    const value = useMemo(
        () => ({
            selectedOrder,
            handleSelectedOrder,
            selectedUser,
            handleSelectedUser,
            selectedTransaction,
            handleSelectedTransaction,
            selectedEscrow,
            handleSelectedEscrow,
            selectedProduct,
            handleSelectedProduct,
            unreadMessage,
            handleSetUnreadMessage,
            handleRemoveUnreadMessage


        }),
        [selectedOrder, selectedUser, selectedTransaction, selectedEscrow, selectedProduct, unreadMessage]
    )




    return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}