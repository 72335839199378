import React, { useEffect, useRef } from "react";
import { useKeyPress } from "../../hooks/useKeyPress";
import clsx from "clsx";

interface ISearch {
	onChange?: any;
	placeholder: string;
	onSubmit?: () => void;
	onClear?: () => void;
	className?: string;
}

const SearchInput = ({
	onChange,
	placeholder,
	onSubmit = () => { },
	onClear = () => { },
	className,
}: ISearch) => {

	const inputRef = useRef<any>()


	return (
		<div
			className={clsx("h-10 hidden relative border-[#52198baf]  bg-white px-3 border shadow-sm rounded-md md:w-full md:flex items-center", className)}
		>
			<img
				src="/icons/close.svg"
				className="w-2 text-pc-grey9 cursor-pointer absolute"
				onClick={() => {
				
				inputRef.current.value = ""
				onChange({ target: { value: "" } }) 
			}}
				style={{ right: "23px", top: "50%", transform: "translateY(-50%)" }}
				alt=""
			/>
			<div className="flex flex-1 h-full items-center">
				<img
					src="/icons/search.svg"
					className="w-5 mr-1"
					alt=""
				/>
				<input
					ref={inputRef}
					onChange={onChange}
					placeholder={placeholder}
					type="text"
					onSubmit={onSubmit}
					className="flex-1 h-full md:w-72 outline-none border-none hover:sh focus:border-none focus:shadow-none focus:outline-none text-sm placeholder-gray-400 text-gray-400 focus:ring-0 py-1 px-2"
				/>
			</div>

		</div>
	);
};

export default SearchInput;
